import React from "react"
import { Container, Box } from "theme-ui"

// import app components
import Header from "./Header"
import Footer from "./Footer"
import LightBox from "./lightBox"
import { LoginDialog } from "../auth/components"
import { useAuthServices } from "../auth"
import Modal from "./Modal"

const Layout = (props) => {
  useAuthServices(props?.location?.pathname)

  return (
    <>
      <Container
        sx={{
          a: {
            textDecoration: "none !important",
          },
          pt: "80px",
          "@media (min-width: 1024px)": {
            pt: "0px",
          },
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            "@media (min-width: 1024px)": {
              position: "absolute",
              top: 0,
              left: 0,
            },
          }}
        >
          <Header {...props} />
        </Box>
        <Box
          as="main"
          {...props}
          sx={{
            minHeight: `calc(100vh - 80px)`,
          }}
        >
          {props?.children}
        </Box>
        <Footer {...props} />
      </Container>
      <LightBox />
      <LoginDialog />
      <Modal {...props} />
    </>
  )
}

export default Layout
