import React from "react"
import styled from "@emotion/styled"
import Parser from "html-react-parser"
import { Box, Text, Divider } from "theme-ui"

import { GatsbyImage } from "jam-cms"

// import app components
import { useStore } from "../../store"

import Logo from "../../icons/pwww_corner.svg"
import Link from "../Link"

const ProductMenu: React.FC<Props> = (props: Props) => {
  const { data } = props

  const [
    {
      appState: { productMenu },
    },
  ] = useStore()

  return (
    <Box
      sx={{
        position: "fixed",
        top: "80px",
        width: "100%",
        bg: "bgDark",
        overflowY: "auto",
        maxHeight: `calc(100vh - 8px)`,
        display: `${productMenu ? null : "none"}`,
        "@media (max-width: 1024px)": { display: "none", bg: "charcoalLight" },
      }}
    >
      <Box
        sx={{
          display: ["flex", "grid"],
          flexDirection: "column",
          py: "30px",
          px: ["0px", "30px", "80px"],
          gridTemplateColumns: [1, "1fr 1fr 1fr 1fr"],
          gridTemplateRows: [null, "1fr"],
          gap: [0, "5px"],
        }}
      >
        <Box
          sx={{
            gridColumnStart: 1,
            gridColumnEnd: 3,
            gridRowStart: 1,
            gridRowEnd: 2,
          }}
        >
          <Text
            variant="text.productHeadline"
            sx={{
              color: "white",
              textTransform: "uppercase",
            }}
          >
            PRODUCTS
          </Text>
          <Divider sx={{ width: "90%", color: "white" }} />
        </Box>
        {data?.productspage?.map((page, idx) => (
          <MenuLink to={page?.pageslug} key={idx} idx={idx}>
            <Box
              sx={{
                gridColumnStart: 1,
                gridColumnEnd: 2,
                gridRowStart: 1,
                gridRowEnd: 2,
                position: "relative",
                zIndex: "-1",
                "&:before": {
                  content: "''",
                  position: "absolute",
                  top: "0",
                  left: 0,
                  width: "100%",
                  height: "100%",
                  background:
                    "linear-gradient(51.28deg, #253746 -3.15%, rgba(37, 55, 70, 0) 46.16%)",
                  zIndex: 1,
                },
                ".gatsby-image-wrapper": {
                  width: "200px",
                  height: "120px",
                  "@media (min-width: 1170px)": {
                    width: "277px",
                    height: "157px",
                  },
                },
              }}
            >
              {page?.image && (
                <GatsbyImage
                  image={page?.image}
                  alt={page?.image.altText}
                  objectPosition="0"
                  objectFit="cover"
                  className="gatsbyImage"
                />
              )}
            </Box>
            <Box
              mt={["auto", "auto"]}
              mr={["auto", "auto"]}
              color="white"
              sx={{
                gridColumnStart: 1,
                gridColumnEnd: 3,
                gridRowStart: 1,
                gridRowEnd: 2,

                svg: {
                  width: "60px",
                  height: "60px",
                  "@media (min-width: 1170px)": {
                    width: "79px",
                    height: "79px",
                  },
                },
              }}
            >
              <Logo />
            </Box>

            <Box
              sx={{
                gridColumnStart: 2,
                gridColumnEnd: 3,
                gridRowStart: 1,
                gridRowEnd: 2,
                display: "flex",
                alignItems: "center",
                pl: ["10px", "30px"],
                wordBreak: "break-all",
              }}
            >
              {page?.text && (
                <Text
                  variant="text.productMenuItem"
                  sx={{
                    a: {
                      textTransform: "lowercase !important",
                    },
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  {Parser(page?.text)}
                </Text>
              )}
            </Box>
          </MenuLink>
        ))}
      </Box>
    </Box>
  )
}

const MenuLink = styled(Link)`
  grid-column-start: ${(props: any) =>
    parseInt(props.idx) === 0 || parseInt(props.idx) % 2 == 0 ? 3 : 1};
  grid-column-end: ${(props: any) =>
    parseInt(props.idx) === 0 || parseInt(props.idx) % 2 == 0 ? 5 : 3};
  display: grid;
  @media (min-width: 1170px) {
    grid-template-columns: 277px 1fr;
  }
  grid-template-columns: 200px 1fr;
`

export default ProductMenu
