import React, { useState } from "react"
import { IconButton, Box, Flex, Text, Button, Container } from "theme-ui"
import { motion, AnimatePresence } from "framer-motion"
import { useStore } from "../../store"
import { Close } from "mdi-material-ui"

// import Edges from "../Edges"
import Link from "../Link"

import ChevronDown from "../../icons/chevron-down.svg"
import theme from "../../theme"

interface MenuItem {
  key: number
  title?: string // the "?" makes this optional
  postID?: number
  postTypeID?: string
  url?: string
  children?: MenuItem[] // array of MenuItem, can also be written as Array<MenuItem>
}

interface Props {
  items?: MenuItem[] // or Array<MenuItem>
}

const DesktopMenu = (props: Props) => {
  const { items } = props

  const [activeMenuIndex, setActiveMenuIndex] = useState(null)

  const handleOpen = (itemIndex: number, hasSubMenu?: boolean) => {
    if (!hasSubMenu) {
      setActiveMenuIndex(null)
      return
    }

    if (itemIndex === activeMenuIndex) {
      setActiveMenuIndex(null)
    } else {
      setActiveMenuIndex(itemIndex)
    }
  }

  const handleClose = () => {
    setActiveMenuIndex(null)
  }
  const [
    {
      appState: { menu: productMenu, aboutMenu },
    },
    dispatch,
  ] = useStore()

  return (
    <Container
      sx={{
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
      }}
    >
      <Flex sx={{ pr: "36px", justifyContent: "flex-end" }}>
        <Flex
          sx={{
            alignItems: "center",
            "@media (max-width: 1023px)": {
              display: "none",
            },
          }}
        >
          {items.map((item: MenuItem, index: number) => (
            <>
              {item?.title?.toUpperCase() === "PRODUCTS" ||
              item?.title?.toUpperCase() === "ABOUT" ? (
                <Button
                  onClick={() =>
                    dispatch({
                      type: `TOGGLE_${item.title.toUpperCase()}_MENU`,
                    })
                  }
                  ml="20px"
                  variant="navButton"
                  sx={{
                    textTransform: "uppercase",
                    minWidth: "80px",
                    height: "100%",
                    cursor: "pointer",
                    display: "flex",
                    position: "relative",
                    flexDirection: "row",
                    alignItems: "center",
                    color: "white",
                    "&:hover": {
                      color: "bgGreen",
                      ".chevron-icon": { color: "bgGreen" },
                    },
                  }}
                >
                  {item.title}
                  <Box
                    className="link-text"
                    sx={{
                      transform: "translateY(3px)",
                    }}
                  >
                    <Box
                      sx={{
                        alignItems: "center",
                        cursor: "pointer",

                        ".chevron-icon": {
                          color: "#fff",
                          transition: "transform 150ms ease-in-out",
                          transform:
                            (productMenu &&
                              item.title.toUpperCase() === "PRODUCTS") ||
                            (aboutMenu && item.title.toUpperCase() === "ABOUT")
                              ? "rotate(-180deg)"
                              : "rotate(0)",
                        },
                      }}
                    >
                      <ChevronDown className="chevron-icon" />
                    </Box>
                  </Box>
                </Button>
              ) : (
                <MenuButton
                  key={item.key}
                  item={item}
                  active={index === activeMenuIndex}
                  onOpen={() =>
                    handleOpen(index, !!(item?.children?.length > 0))
                  }
                  onClose={handleClose}
                />
              )}
            </>
          ))}
        </Flex>
      </Flex>
    </Container>
  )
}

export default DesktopMenu

interface MenuButtonProps {
  active?: boolean
  item?: MenuItem
  onOpen?: () => void // this is how to define a function type
  onClose?: () => void
}

const MenuButton = (props: MenuButtonProps) => {
  const {
    item: { title, children, url },
    onClose,
    onOpen,
    active,
  } = props

  const linkProps =
    !children || children?.length === 0
      ? {
          as: Link, // make Button function as a Link if no children
          to: url,
          activeStyle: {
            "&:before": {
              opacity: 1,
            },
          },
        }
      : {}

  return (
    <>
      <Button
        {...linkProps}
        ml="20px"
        variant="navButton"
        onClick={onOpen}
        sx={{
          height: "80px",
          textTransform: "uppercase",
          whiteSpace: "nowrap",
          cursor: "pointer",
          display: "flex",
          position: "relative",
          flexDirection: "row",
          alignItems: "center",
          color: "white",
          "&:hover": {
            ".link-text": { color: "bgGreen" },
            ".chevron-icon": { color: "bgGreen" },
          },
        }}
      >
        <Box className="link-text">{title}</Box>

        {children?.length > 0 && <ChevronDown className="chevron-icon" />}
      </Button>

      <AnimatePresence>
        {active && children?.length > 0 && (
          <SubMenu items={children} onClose={onClose} />
        )}
      </AnimatePresence>
    </>
  )
}

const SubMenu = (props: { items?: MenuItem[]; onClose?: () => void }) => {
  // the types can also be defined ^here^ instead of creating an interface
  const { items, onClose } = props

  return (
    <>
      <Box // click away listener
        onClick={onClose}
        sx={{
          zIndex: -1,
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: "100%",
          height: "100vh",
        }}
      />

      <motion.div
        initial={{ opacity: 0, y: "-100%" }}
        animate={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
        exit={{ opacity: 0, y: "-100%", transition: { duration: 0.3 } }}
        style={{
          zIndex: -1,
          display: "flex",
          padding: "85px 85px 55px",
          background: theme.colors.charcoalDark,
          position: "absolute",
          flexDirection: "column",
          right: 0,
          top: 94,
          maxHeight: "calc(100vh - 94px)",
          width: "50%",
          overflow: "auto",
        }}
      >
        <IconButton
          onClick={onClose}
          color="white"
          sx={{
            cursor: "pointer",
            position: "absolute",
            top: 40,
            right: 40,
            "> svg": {
              width: "35px",
              height: "35px",
            },
          }}
        >
          <Close />
        </IconButton>

        {items &&
          items.map((item: MenuItem, index: number) => (
            <Link
              key={index}
              to={item.url}
              onClick={onClose}
              aria-label={item.title}
              title={item.title}
              style={{
                display: "inline-block",
                marginBottom: "40px",
                color: "white",
                "&:hover": {
                  color: theme.colors.coral,
                },
              }}
              activeStyle={{ color: theme.colors.coral }}
            >
              <Text variant="desktopSubMenuItem" color="white">
                {item.title}
              </Text>
            </Link>
          ))}
      </motion.div>
    </>
  )
}
