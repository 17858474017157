import React, { useState } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/css"
import { Link } from "gatsby"
import Parser from "html-react-parser"
import { Text, Flex, Box, Divider } from "theme-ui"
import { GatsbyImage } from "jam-cms"
import { navigate } from "gatsby"

// import app components
import ChevronDown from "../../icons/chevron-down.svg"
import { useStore } from "../../store"
import { formatLink } from "../../utils"
import theme from "../../theme"
import ProductMenu from "./ProductMenu"
import Logo from "../../icons/pwww_corner.svg"
import LinkComponent from "../../components/Link"
interface Props {
  items?: [any]
  productmenu?: any
  aboutmenu?: any
}

const MobileMenu: React.FC<Props> = (props: Props) => {
  const { items, productmenu, aboutmenu } = props

  const [
    {
      appState: { menu },
    },
    dispatch,
  ] = useStore()

  const itemIds = items ? items.map(({ key }) => key) : [] // set all items open by default

  const [activeItems, setActiveItems] = useState([...itemIds])
  const [activeProducts, setActiveProducts] = useState(false)

  const handleActiveProducts = (item) => {
    if (activeProducts === item) {
      setActiveProducts(false)
    } else {
      setActiveProducts(item)
    }
  }

  const handleArrowClick = (id: any) => {
    let newItems = [...activeItems]

    if (activeItems.includes(id)) {
      newItems = newItems.filter((i) => i !== id)
    } else {
      newItems.push(id)
    }

    setActiveItems(newItems)
  }

  const handleCloseMenu = () => dispatch({ type: "SET_MENU", payload: false })

  const RenderSubMenu = ({ data }) => {
    return (
      <>
        {data?.map((page, idx) => (
          <MenuLinkProduct to={page?.pageslug} key={idx} idx={idx}>
            <Box
              sx={{
                gridColumnStart: 1,
                gridColumnEnd: 2,
                gridRowStart: 1,
                gridRowEnd: 2,
                ".gatsby-image-wrapper": {
                  width: "100px",
                  height: "50px",
                  maxHeight: "50px",
                  position: "relative",
                  zIndex: "-1",
                  "&:before": {
                    content: "''",
                    position: "absolute",
                    top: "0",
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background:
                      "linear-gradient(51.28deg, #253746 -3.15%, rgba(37, 55, 70, 0) 46.16%)",
                    zIndex: 1,
                  },
                  "@media (min-width: 1024px)": {
                    width: "100px",
                    height: "50px",
                    maxHeight: "50px",
                  },
                },
              }}
            >
              {page?.image && (
                <GatsbyImage
                  image={page?.image}
                  alt={page?.image.altText}
                  objectPosition="0"
                  objectFit="cover"
                  className="gatsbyImage"
                />
              )}
            </Box>
            <Box
              mt={["auto", "auto"]}
              mr={["auto", "auto"]}
              color="white"
              sx={{
                gridColumnStart: 1,
                gridColumnEnd: 2,
                gridRowStart: 1,
                gridRowEnd: 2,
                width: "50px",

                svg: {
                  width: "20px",
                  height: "20px",
                },
              }}
            >
              <Logo />
            </Box>
            <Box
              sx={{
                gridColumnStart: 2,
                gridColumnEnd: 3,
                gridRowStart: 1,
                gridRowEnd: 2,
                display: "flex",
                alignItems: "center",
                pl: ["10px", "30px"],
                wordBreak: "break-all",
              }}
            >
              {page?.text && (
                <Text
                  sx={{
                    color: "white",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  {Parser(page?.text)}
                </Text>
              )}
            </Box>
          </MenuLinkProduct>
        ))}
      </>
    )
  }

  return (
    <>
      <Menu {...props} menuState={menu}>
        {items &&
          items.map(({ key: id, url, title, children }) => {
            return (
              <MenuItem key={id}>
                {children?.length ? (
                  <>
                    {url === "#" ? (
                      <MenuLinkContainer>
                        <Text variant="mobileMenuItem" color="white">
                          {title && Parser(title)}
                        </Text>

                        <Chevron active={activeItems.includes(id)} />
                      </MenuLinkContainer>
                    ) : (
                      <MenuLinkContainer>
                        <MenuLink
                          to={formatLink(url)}
                          activeStyle={{ color: "white" }}
                        >
                          <Text variant="mobileMenuItem" color="white">
                            {title && Parser(title)}
                          </Text>
                        </MenuLink>

                        <Chevron active={activeItems.includes(id)} />
                      </MenuLinkContainer>
                    )}

                    <SubMenu className={`sub-menu`}>
                      {children.map((o, i) => {
                        return (
                          <SubMenuLink
                            key={i}
                            activeClassName="active"
                            activeStyle={{ color: "white" }}
                          >
                            <Text variant="mobileSubMenuItem" color="white">
                              {o?.title && Parser(o.title)}
                            </Text>
                          </SubMenuLink>
                        )
                      })}
                    </SubMenu>
                  </>
                ) : (
                  <>
                    {title.toUpperCase() === "PRODUCTS" ||
                    title.toUpperCase() === "ABOUT" ? (
                      <Box>
                        <Flex
                          sx={{
                            "&:hover": {
                              cursor: "pointer",
                              alignItems: "center",
                            },
                          }}
                        >
                          <Box
                            onClick={() =>
                              handleActiveProducts(title.toUpperCase())
                            }
                          >
                            <Text
                              onClick={() =>
                                handleActiveProducts(title.toUpperCase())
                              }
                              color="white"
                            >
                              {title && Parser(title)}
                            </Text>
                          </Box>
                          <Chevron
                            active={activeProducts === title.toUpperCase()}
                            onClick={() =>
                              handleActiveProducts(title.toUpperCase())
                            }
                          />
                        </Flex>
                        <Box
                          sx={{
                            pt: "30px",
                            display: `${
                              activeProducts === title.toUpperCase()
                                ? null
                                : "none"
                            }`,
                          }}
                        >
                          <RenderSubMenu
                            data={
                              title.toUpperCase() === "PRODUCTS"
                                ? productmenu?.productspage
                                : aboutmenu?.aboutpage
                            }
                          />
                        </Box>
                      </Box>
                    ) : (
                      <MenuLink
                        to={formatLink(url)}
                        activeStyle={{ color: theme.colors.bgGreen }}
                      >
                        <Text color="white" variant="mobileMenuItem">
                          {title && Parser(title)}
                        </Text>
                      </MenuLink>
                    )}
                  </>
                )}
              </MenuItem>
            )
          })}
      </Menu>

      <Gradient
        onClick={() => dispatch({ type: "SET_MENU", payload: false })}
        menuState={menu}
      />
    </>
  )
}

const Chevron = (props) => {
  const { active, onClick } = props

  return (
    <Flex
      onClick={onClick}
      sx={{
        alignItems: "center",
        padding: "0 12px",
        cursor: "pointer",

        ".chevron-icon": {
          color: "#fff",
          transition: "transform 150ms ease-in-out",
          transform: active ? "rotate(-180deg)" : "rotate(0)",
        },
      }}
    >
      <ChevronDown className="chevron-icon" />
    </Flex>
  )
}

const Menu = styled.div`
  ${(props: any) =>
    props.menuState
      ? `
          transform: translateX(0);
          opacity: 1;
        `
      : `
          transform: translateX(120%);
          opacity: 0;
        `};

  position: fixed;
  top: 80px;
  width: 100%;
  right: 0;
  z-index: -2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  background: ${theme.colors.charcoalLight};
  padding-top: 32px;
  padding-bottom: 32px;
  height: calc(100vh - 80px);
  overflow-y: auto;

  @media (min-width: 1024px) {
    display: none;
  }
`

const MenuLinkProduct = styled(LinkComponent)`
  grid-column-start: ${(props: any) =>
    parseInt(props.idx) === 0 || parseInt(props.idx) % 2 == 0 ? 3 : 1};
  grid-column-end: ${(props: any) =>
    parseInt(props.idx) === 0 || parseInt(props.idx) % 2 == 0 ? 5 : 3};
  display: grid;
  grid-template-columns: 1fr 2fr;
`
const ChevronContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 12px;
  cursor: pointer;
`

const item = css`
  position: relative;
  color: #fff;
  text-decoration: none;
  width: 100%;
`

const MenuLinkContainer = styled.div`
  display: flex;
  cursor: pointer;
  ${item};
  color: #fff;
  margin-bottom: 10px;
`

const MenuItem = styled.div`
  padding: 0 30px;
  margin-bottom: 40px;
  ${item};
  color: #fff;
`

const MenuLink = styled(Link)`
  ${item};
  color: #fff;
  &:hover {
    color: ${theme.colors.bgGreen};
  }
`

const SubMenu = styled.div`
  display: ${(props: any) => (props.active ? "block" : "none")};
`

const SubMenuLink = styled(Link)`
  display: block;
  padding: 12px;

  color: ${theme.colors.almond};

  &:hover {
    color: ${theme.colors.bgGreen};
  }
`

const Gradient = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 80px;
  z-index: -3;
  /* background: rgba(0, 0, 0, 0.5); */
  opacity: ${(props: any) => (!!props.menuState ? 1 : 0)};
  pointer-events: ${(props: any) => (!!props.menuState ? "all" : "none")};
  transition: ease all 0.2s;
`

export default MobileMenu
