import React, { useEffect } from "react"
import HamburgerMenu from "react-hamburger-menu"
import { Container, Box, Grid } from "theme-ui"
import useScroll from "@react-hooks-custom/use-scroll"

import useThemeOptions from "../hooks/useThemeOptions"
import useMenuItems from "../hooks/useMenuItems"

// import app components
import Link from "./Link"
// import Edges from "./Edges"
import DesktopMenu from "./menu/DesktopMenu"
import MobileMenu from "./menu/MobileMenu"
import ProductMenu from "./menu/ProductMenu"
import AboutMenu from "./menu/AboutMenu"

import { useStore } from "../store"

import Logo from "../icons/PWWW_logo.svg"

const Header = (props) => {
  const { path } = props

  const {
    header: { productmenu, aboutmenu },
  } = useThemeOptions()
  const menu = useMenuItems({ slug: `menu` })

  const [
    {
      appState: { menu: menuActive },
    },
    dispatch,
  ] = useStore()

  useEffect(() => {
    dispatch({ type: "SET_MENU", payload: false })
  }, [path, dispatch])

  const { scrollX, scrollY } = useScroll()

  return (
    <Container
      sx={{
        height: "80px",
        position: "fixed",
        left: 0,
        top: 0,
        zIndex: 100,
        width: ["100%", "100%", "100%"],
        bg: "bgDark",
        filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
        "@media (min-width: 1024px)": {
          bg: scrollY > scrollX ? "bgDark" : "rgba(0, 0, 0, 0.4)",
          borderBottom: scrollY > scrollX ? "none" : "1px solid white",
        },
      }}
    >
      <Grid
        sx={{
          postion: "relative",
          height: "80px",
          zIndex: 9,
          gridRow: 1,
          gap: 0,
        }}
        columns={[1, "20% 80%", "20% 80%"]}
      >
        {(!props?.isHomePage || scrollY >= 300) && (
          <Box
            pr={2}
            sx={{
              position: "relative",
              zIndex: 2,
              textDecoration: "none",
              bg: "bgDark",
              gridColumnStart: 1,
              gridColumnEnd: 2,
              gridRowStart: 1,
              maxHeight: "80px",
              display: "flex",
              alignItems: "center",
              justifyContent: ["flex-start", "center"],
              pl: [3, 4, "none"],
              "@media (min-width: 1024px)": {
                borderBottom:
                  scrollY > scrollX ? "none" : ["none", "1px solid white"],
              },
            }}
          >
            <Link to="/">
              <Box
                sx={{
                  maxHeight: "80px",
                  minHeight: "80px",
                  height: "80px",
                  svg: {
                    pt: 2,
                    maxHeight: "75px",
                    minHeight: "75px",
                    height: "75px",
                    width: "160px",
                    mx: "auto",
                  },
                }}
              >
                <Logo />
              </Box>
            </Link>
          </Box>
        )}
        <Box
          sx={{
            bg: "bgDark",
            "@media (min-width: 1024px)": {
              bg: scrollY > scrollX ? "bgDark" : "transparent",
              borderLeft:
                scrollY > scrollX || props?.isHomePage
                  ? "none"
                  : "1px solid white",
              borderBottom:
                scrollY > scrollX || props?.isHomePage
                  ? "none"
                  : ["none", "1px solid white"],
            },

            position: "relative",
            zIndex: 2,
            gridColumnStart: 2,
            gridColumnEnd: 3,
            gridRowStart: 1,
            maxHeight: "80px",

            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <DesktopMenu items={menu} />

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              transform: "translateX(15px)",
              cursor: "pointer",
              "@media (min-width: 1024px)": { display: "none" },
            }}
            p={40}
            onClick={() => dispatch({ type: "TOGGLE_MENU" })}
          >
            <HamburgerMenu
              color="#fff"
              isOpen={menuActive}
              width={26}
              height={15}
              strokeWidth={2}
              menuClicked={() => ""}
            />
          </Box>

          <MobileMenu
            items={menu}
            productmenu={productmenu}
            aboutmenu={aboutmenu}
          />
          <ProductMenu data={productmenu} />
          <AboutMenu data={aboutmenu} />
        </Box>
      </Grid>
    </Container>
  )
}

export default Header
