import React from "react"
import { Container, Box, Grid, Text, Link as ThemeLink } from "theme-ui"

// import app components
import Edges from "./Edges"
import Link from "./Link"

import Logo from "../icons/PWWW_logo.svg"
import LogoNawla from "../icons/nawla-white.svg"
import LogoBcWood from "../icons/bc-wood-white.svg"
import LogoRealCedar from "../icons/real-cedar-white.svg"

import useThemeOptions from "../hooks/useThemeOptions"
import useMenuItems from "../hooks/useMenuItems"

const Footer = () => {
  const {
    footer: { emailaddress, phonenumber, fax },
  } = useThemeOptions()

  const footerMenu = useMenuItems({ slug: `footermenu` })

  return (
    <Container>
      <Grid
        sx={{ minHeight: "155px", gap: 0 }}
        columns={["40% 60%", "60% 40%", "80% 20%"]}
      >
        <Box
          pr={2}
          sx={{
            position: "relative",
            zIndex: 2,
            textDecoration: "none",
            bg: "bgDark",
            background:
              "linear-gradient(228.65deg, #253746 31.43%, #000000 131.65%)",
            svg: {
              height: "60px",
              mx: "auto",
            },
            gridColumnStart: 1,
            gridColumnEnd: 2,
            gridRowStart: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: ["center", "flex-end"],
            pl: [3, 4, "none"],
          }}
        >
          <Link to="/">
            <Box
              sx={{
                svg: {
                  pt: 2,
                  height: "auto",
                  width: ["140px", "140px", "160px"],
                },
              }}
            >
              <Logo />
            </Box>
          </Link>
        </Box>
        <Box
          bg="bgGreen"
          sx={{
            minHeight: "155px",
            gridColumnStart: 2,
            gridColumnEnd: 3,
            gridRowStart: 1,
            display: "flex",
            flexDirection: "column",
            color: "white",
            alignItems: "flex-start",
            justifyContent: "center",
            wordBreak: "break-all",
            px: 4,
            position: "relative",
            zIndex: 10,
            py: "22px",
          }}
        >
          {phonenumber && (
            <Box mb="5px">
              <Text sx={{ display: "inline-block", color: "white", pr: 2 }}>
                T
              </Text>

              <ThemeLink
                sx={{
                  textDecoration: "none",
                  color: "white",
                  "&:hover": { color: "notice" },
                }}
                href={`tel:${phonenumber}`}
                variant="hyperlink"
              >
                {phonenumber}
              </ThemeLink>
            </Box>
          )}
          {fax && (
            <Box mb="5px">
              <Text sx={{ display: "inline-block", color: "white", pr: 2 }}>
                F
              </Text>

              <ThemeLink
                sx={{
                  textDecoration: "none",
                  color: "white",
                  "&:hover": { color: "notice" },
                }}
                href={`tel:${fax}`}
                variant="hyperlink"
              >
                {fax}
              </ThemeLink>
            </Box>
          )}
          {emailaddress && (
            <Box mb="20px">
              <ThemeLink
                sx={{
                  textDecoration: "none",
                  color: "white",
                  "&:hover": { color: "notice" },
                }}
                href={`mailto:${emailaddress}`}
                variant="hyperlink"
              >
                {emailaddress}
              </ThemeLink>
            </Box>
          )}

          {footerMenu &&
            footerMenu.map((o, i) => {
              return (
                <ThemeLink
                  key={i}
                  href={o.url}
                  color="white"
                  mb="5px"
                  variant="hyperlink"
                >
                  <Text
                    sx={{
                      "&:hover": { color: "notice" },
                    }}
                  >
                    {o.title}
                  </Text>
                </ThemeLink>
              )
            })}
        </Box>
      </Grid>
      <Box bg="black">
        <Edges size="md">
          <Box
            sx={{
              height: "86px",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <Box>
              {" "}
              <Link to="https://www.nawla.org">
                <Box
                  sx={{
                    svg: { width: ["80%", "100%"] },
                  }}
                >
                  <LogoNawla />
                </Box>
              </Link>
            </Box>
            <Box>
              <Link to="https://bcwood.com">
                <Box
                  sx={{
                    svg: { width: ["80%", "100%"] },
                  }}
                >
                  <LogoBcWood />
                </Box>
              </Link>
            </Box>
            <Box>
              <Link to="https://www.realcedar.com">
                <Box
                  sx={{
                    my: "auto",
                    svg: { width: ["80%", "100%"], my: "auto" },
                  }}
                >
                  <LogoRealCedar />
                </Box>
              </Link>
            </Box>
          </Box>
        </Edges>
      </Box>
      <Box
        bg="white"
        color="copyRightText"
        sx={{
          minHeight: "32px",
          px: [2, 5],
          py: [2, "null"],
          display: "flex",
          alignItems: "center",
          justifyContent: ["center", "flex-end"],
        }}
      >
        <Text variant="footerLegal">
          © 2021 pacific western wood works. All rights reserved. gatsby website
          development by
          <Text
            variant="footerLegal"
            px={1}
            as="a"
            color={"bgGreen"}
            sx={{
              "&:hover": { color: "bgGreen", textDecoration: "underline" },
            }}
            href="https://jambaree.com/"
          >
            Jambaree
          </Text>
        </Text>
      </Box>
    </Container>
  )
}

// const FooterLogo = () => (
//   <Box
//     mr="24px"
//     sx={{
//       "> svg": {
//         width: "100%",
//         height: "auto",
//       },
//       ".logo-vert": {
//         display: "none",
//       },
//       ".logo-hor": {
//         marginBottom: "60px",
//       },

//       width: "160px",

//       "@media (min-width: 800px)": {
//         marginRight: "60px",
//         width: "35px",

//         ".logo-vert": {
//           display: "block",
//         },

//         ".logo-hor": {
//           display: "none",
//         },
//       },

//       "@media (min-width: 1100px)": {
//         marginRight: "120px",
//       },
//     }}
//   >
//     <LogoVertical className="logo-vert" />

//     <Logo className="logo-hor" />
//   </Box>
// )

// interface LegalTextProps {
//   copyright?: string
//   disclaimer?: string
//   mobile?: boolean
// }

// const LegalText: React.FC<LegalTextProps> = (props: LegalTextProps) => {
//   const { copyright, disclaimer, mobile } = props

//   return (
//     <Box
//       aria-hidden={!!mobile}
//       sx={{
//         maxWidth: "520px",
//         display: mobile ? "block" : "none",
//         "@media (min-width: 800px)": {
//           display: mobile ? "none" : "block",
//         },
//       }}
//     >
//       {copyright && (
//         <Text
//           sx={{
//             fontFamily: "body",
//             display: "block",
//             color: "white",
//             fontSize: 0,
//           }}
//         >
//           {copyright}
//         </Text>
//       )}

//       {disclaimer && (
//         <Text
//           sx={{
//             fontFamily: "body",
//             display: "block",
//             color: "black50",
//             fontSize: 0,
//           }}
//         >
//           {disclaimer}
//         </Text>
//       )}
//     </Box>
//   )
// }

export default Footer
