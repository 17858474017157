import { useStaticQuery, graphql } from "gatsby"

export const useThemeOptions = () => {
  const {
    wp: {
      themeOptions: { themeOptions },
    },
  } = useStaticQuery(graphql`
    query {
      wp {
        themeOptions {
          themeOptions {
            fieldGroupName
            footer {
              emailaddress
              fax
              fieldGroupName
              phonenumber
            }
            header {
              fieldGroupName
              aboutmenu {
                aboutpage {
                  text
                  pageslug
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(width: 350)
                      }
                    }
                  }
                }
              }
              productmenu {
                productspage {
                  text
                  pageslug
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(width: 350)
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return themeOptions
}

export default useThemeOptions
