import React, { useState } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/css"
import { navigate } from "gatsby"
import Parser from "html-react-parser"
import { Container, Box, Flex, Text, Divider } from "theme-ui"
import { graphql } from "gatsby"
import { GatsbyImage } from "jam-cms"

// import app components
import ChevronDown from "../../icons/chevron-down.svg"
import { useStore } from "../../store"
import { formatLink } from "../../utils"
import theme from "../../theme"
import Logo from "../../icons/pwww_corner.svg"
import Link from "../Link"

const AboutMenu: React.FC<Props> = (props: Props) => {
  const { data, aboutItem } = props

  const [
    {
      appState: { aboutMenu },
      userState: { isLoggedIn, user },
    },
    dispatch,
  ] = useStore()

  return (
    <Box
      sx={{
        position: "fixed",
        top: "80px",
        width: "100%",
        bg: "bgDark",
        overflowY: "auto",
        maxHeight: `calc(100vh - 8px)`,
        display: `${aboutMenu ? null : "none"}`,
        "@media (max-width: 1024px)": { display: "none", bg: "charcoalLight" },
      }}
    >
      <Box
        sx={{
          display: ["flex", "grid"],
          flexDirection: "column",
          py: "30px",
          px: ["0px", "30px", "80px"],
          gridTemplateColumns: [1, "1fr 1fr 1fr 1fr"],
          gridTemplateRows: [null, "1fr"],
          gap: [0, "5px"],
        }}
      >
        <Box
          sx={{
            gridColumnStart: 1,
            gridColumnEnd: 3,
            gridRowStart: 1,
            gridRowEnd: 2,
            pb: 5,
          }}
        >
          <Text
            variant={"text.productHeadline"}
            sx={{
              color: "white",
              textTransform: "uppercase",
            }}
          >
            ABOUT
          </Text>
          <Divider sx={{ width: "90%", color: "white" }} />
        </Box>
        {data?.aboutpage?.map((page, idx) => (
          <MenuLink to={page?.pageslug} key={idx}>
            <Box
              sx={{
                gridColumnStart: 1,
                gridColumnEnd: 2,
                gridRowStart: 1,
                gridRowEnd: 2,
                position: "relative",
                zIndex: "-1",
                "&:before": {
                  content: "''",
                  position: "absolute",
                  top: "0",
                  left: 0,
                  width: "100%",
                  height: "100%",
                  background:
                    "linear-gradient(51.28deg, #253746 -3.15%, rgba(37, 55, 70, 0) 46.16%)",
                  zIndex: 1,
                },
                ".gatsby-image-wrapper": {
                  width: "200px",
                  height: "120px",
                  "@media (min-width: 1170px)": {
                    width: "277px",
                    height: "157px",
                  },
                },
              }}
            >
              {page?.image && (
                <GatsbyImage
                  image={page?.image}
                  alt={page?.image.altText}
                  objectPosition="0"
                  objectFit="cover"
                  className="gatsbyImage"
                />
              )}
            </Box>
            <Box
              mt={["auto", "auto"]}
              mr={["auto", "auto"]}
              color="white"
              sx={{
                gridColumnStart: 1,
                gridColumnEnd: 3,
                gridRowStart: 1,
                gridRowEnd: 2,

                svg: {
                  width: "60px",
                  height: "60px",
                  "@media (min-width: 1170px)": {
                    width: "79px",
                    height: "79px",
                  },
                },
              }}
            >
              <Logo />
            </Box>

            <Box
              sx={{
                gridColumnStart: 2,
                gridColumnEnd: 3,
                gridRowStart: 1,
                gridRowEnd: 2,
                display: "flex",
                alignItems: "center",
                pl: ["10px", "30px"],
                wordBreak: "break-all",
              }}
            >
              {page?.text && (
                <Text
                  variant={"text.productMenuItem"}
                  sx={{
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  {Parser(page?.text)}
                </Text>
              )}
            </Box>
          </MenuLink>
        ))}
      </Box>
    </Box>
  )
}

const Chevron = (props) => {
  const { active, onClick } = props

  return (
    <Flex
      onClick={onClick}
      sx={{
        alignItems: "center",
        padding: "0 12px",
        cursor: "pointer",

        ".chevron-icon": {
          color: "#fff",
          transition: "transform 150ms ease-in-out",
          transform: active ? "rotate(-180deg)" : "rotate(0)",
        },
      }}
    >
      <ChevronDown className="chevron-icon" />
    </Flex>
  )
}

const Menu = styled.div`
  position: fixed;
  top: 80px;
  width: calc(100% - 70px);
  right: 0;
  z-index: -2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  background: #253746;
  padding-top: 32px;
  padding-bottom: 32px;
  max-height: calc(100vh - 80px);
  overflow-y: auto;

  @media (min-width: 1024px) {
    /* display: none; */
  }
`

const ChevronContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 12px;
  cursor: pointer;
`

const item = css`
  position: relative;
  color: #fff;
  text-decoration: none;
  width: 100%;
`

const MenuLinkContainer = styled.div`
  display: flex;
  cursor: pointer;
  ${item};
  color: #fff;
  margin-bottom: 10px;
`

const MenuItem = styled.div`
  padding: 0 30px;
  margin-bottom: 40px;
  ${item};
  color: #fff;
`

const MenuLink = styled(Link)`
  grid-column-start: 1;
  grid-column-end: 4;
  display: grid;
  @media (min-width: 1170px) {
    grid-template-columns: 277px 1fr;
  }
  grid-template-columns: 200px 1fr;
`

const SubMenu = styled.div`
  display: ${(props: any) => (props.active ? "block" : "none")};
`

const SubMenuLink = styled(Link)`
  display: block;
  padding: 12px;

  color: ${theme.colors.almond};

  &:hover {
    color: ${theme.colors.coral};
  }
`

const Gradient = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 80px;
  z-index: -3;
  /* background: rgba(0, 0, 0, 0.5); */
  opacity: ${(props: any) => (!!props.menuState ? 1 : 0)};
  pointer-events: ${(props: any) => (!!props.menuState ? "all" : "none")};
  transition: ease all 0.2s;
`

export default AboutMenu
